import React from "react";
import Banner from "../../common-sections/Banner";
import GetStartedCta from "../../common-sections/GetStartedCta";
import PistonDashboard from "../../common-sections/PistonDashboard";
import ClientReview from "../../common-sections/ClientReview";
import ContactUs from "../../common-sections/ContactUs";
import VideoSec from "../../common-sections/VideoSec";
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import GsapContainer from "../../common-sections/GsapContainer";

gsap.registerPlugin(ScrollTrigger);

const HomepageView = () => {
  const reviewData = [
    {
      desc: "Since moving to Piston from Wex, not only have we seen a marked decrease in gallons used, we are also averaging 4% in savings! We are saving $1000’s every week!",
      username: "Alex",
      designation: "Owner, Camil Express",
    },
    {
      desc: "Not only does Piston ensure that my drivers go to the right station, they deliver in substantial savings for us. Their QR system means we do not have to keep a track of physical cards anymore, saving us countless headaches.",
      username: "Henry E",
      designation: "Owner, Purple Express",
    },
    {
      desc: "We earned a measly 1.5% cash back on our credit card with ZERO fraud control. With Piston, we are averaging over 4%",
      username: "Marcello D",
      designation: "Owner, MRD",
    },
    {
      desc: "I thought we were getting the cheapest gas with Fleetcor. Piston not only beat them, but our reporting now takes seconds instead of hours trying to reconcile which driver filled up what vehicle on what day of the week.",
      username: "Robson R",
      designation: "Owner, Apollo Transport",
    },
  ];

  return (
    <>
      <div>
        <Banner />
      </div>
      {/* <div ref={(el) => (sections.current[0] = el)}>
        <AboutUs />
      </div>
      <div ref={(el) => (sections.current[1] = el)}>
        <PartnerSupport numberOfItems={3} arrayData={cardData} />
      </div> */}
      <div>
        <GsapContainer />
      </div>
      <div>
        <GetStartedCta
          title="Join MRD to save $1000s on your bill"
          available={false}
        />
      </div>
      <div>
        <PistonDashboard desc="Reduce administrative burden by using Piston's intuitive dashboard to manage your fleet and drivers" />
      </div>
      <div>
        <VideoSec src="https://www.youtube.com/embed/aAYX48oRtgw?si=_364SJe6e7LGFHVC" />
      </div>
      <div>
        <ContactUs />
      </div>
      <div>
        <ClientReview
          reviewData={reviewData}
          title={
            <h2 className="lg:text-4xl md:text-2xl text-xl font-medium text-center">
              Fuel Your Fleet with{" "}
              <span className="text-dark-red shadow-red relative">
                Confidence{" "}
              </span>
            </h2>
          }
          subTitle={"Hear what our partners have to say"}
        />
      </div>
    </>
  );
};

export default HomepageView;
